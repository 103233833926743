import React, { useEffect, useState } from 'react';
import Tesseract from 'tesseract.js';
import { v4 as uuidv4 } from 'uuid';
import { UserFeedbackRequest } from '../../../model/feedback';
import { ActionEnum, PosthogEventsNames } from '../../../model/base';
import { useMailboxContent } from '../../../provider/MailboxContentProvider';
import { MessageRole } from '../../../model/chat';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import * as pdfjsLib from 'pdfjs-dist';
import { useAppContext } from '../../../provider/appProvider';
import { usePostHog } from 'posthog-js/react';
import { QCounsel } from 'qanoonisharedpackage';

interface ChatContainerProps {}
export const ChatContainer: React.FC<ChatContainerProps> = ({}) => {
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [failure, setfailure] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const { user_id } = useAppContext();
  const {
    addAttachMessage,
    isSocketReady,
    userOfflineProcess,
    featureRefId,
    traceId,
    socketRef,
    chatMessages,
    sendChatMessage,
    removeChatMessage,
    clearQcounselMessages,
  } = useMailboxContent();
  const { isStreaming, setIsStreaming } = useAppContext();
  const posthog = usePostHog();
  const [isRegenerating, setIsRegenerating] = useState(false);

  const handleRegenerate = async (messageId: string) => {
    console.log('regenerate pressed');
    setIsRegenerating(true);

    // Find the index of the message to regenerate
    const messageIndex = chatMessages.findIndex(msg => msg.id === messageId);
    if (messageIndex === -1) {
      console.error('Message not found');
      setIsRegenerating(false);
      return;
    }

    // Remove the message and all subsequent messages
    for (let i = chatMessages.length - 1; i >= messageIndex; i--) {
      removeChatMessage(chatMessages[i].id);
    }

    // Get the last user message before the removed message
    let lastUserMessage = '';
    for (let i = messageIndex - 1; i >= 0; i--) {
      if (chatMessages[i].role === MessageRole.User) {
        lastUserMessage = chatMessages[i].message;
        break;
      }
    }

    // Regenerate the response
    try {
      await sendChatMessage(
        {
          id: uuidv4(),
          message: lastUserMessage,
          role: MessageRole.User,
          isChunk: false,
          attached: null,
          time: new Date(),
        },
        false,
        sessionId
      );
    } catch (error) {
      console.error('Error regenerating message:', error);
    }

    setIsRegenerating(false);
  };

  const sendFeedbackGet = (
    feedbackType: 'thumbs_up' | 'thumbs_down',
    feedbackContent: string
  ) => {
    const request: UserFeedbackRequest = {
      action: ActionEnum.UserFeedbackRequest,
      feature_type: 'QCouncel chat',
      feature_ref_id: featureRefId,
      feedback: feedbackType,
      trace_id: traceId,
      note: feedbackContent,
    };

    console.log('Sending feedback', request);
    if (socketRef && socketRef.readyState === WebSocket.OPEN) {
      socketRef.send(JSON.stringify(request));
    } else {
      console.error('WebSocket is not open. Unable to send feedback.');
    }
    // Close the modal after sending feedback
    console.log('Sent FeedbackGetRequest to server', request);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (!selectedFile) {
      setLoading(false);
      return;
    }

    const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
    setLoading(true);
    setfailure(false);

    try {
      if (fileExtension === 'txt') {
        // Handle .txt files directly
        const text = await selectedFile.text();
        console.log('attached text:', text);
        setFileContent(text);
      } else if (fileExtension === 'docx') {
        // Handle .docx files
        const arrayBuffer = await selectedFile.arrayBuffer();
        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip);
        const text = doc.getFullText();
        console.log('attached text:', text);
        setFileContent(text);
      } else if (fileExtension === 'pdf') {
        // Handle .pdf files
        console.log('Processing PDF file');
        const arrayBuffer = await selectedFile.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        const pdf = await pdfjsLib.getDocument({
          data: uint8Array,
          worker: null,
        }).promise;
        let text = '';

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          let pageText = textContent.items
            .map((item: any) => ('str' in item ? item.str : ''))
            .join(' ');

          // If the extracted text is empty, attempt OCR
          if (!pageText.trim()) {
            console.log(`Page ${i} has no selectable text, using OCR...`);
            const scale = 2;
            const viewport = page.getViewport({ scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            const renderContext = { canvasContext: context, viewport };
            await page.render(renderContext).promise;

            // Use Tesseract.js to perform OCR with Arabic and English support
            const ocrResult = await Tesseract.recognize(
              canvas,
              'eng+ara', // Support both English and Arabic
              {}
            );
            pageText = ocrResult.data.text;
          }

          text += pageText + '\n';
        }

        // If no text was found using both methods, show an error message
        if (!text.trim()) {
          console.error(
            'Error: PDF contains no selectable or extractable text.'
          );
          addAttachMessage({
            id: uuidv4(),
            message: `Failed to extract text from ${selectedFile.name}. Both native text extraction and OCR failed. Please ensure the PDF is not corrupted or password-protected.`,
            role: MessageRole.Attach,
            isChunk: false,
            attached: null,
          });
          return;
        }

        console.log('attached text:', text);
        setFileContent(text);
      } else {
        // Handle unsupported file extensions
        console.log(`Unsupported file type: ${fileExtension}`);
        addAttachMessage({
          id: uuidv4(),
          message: `Failed to attach ${selectedFile.name}. Supported file types are PDF, DOCX, and TXT`,
          role: MessageRole.Attach,
          isChunk: false,
          attached: null,
        });
        return; // Exit the function if the file type is unsupported
      }

      addAttachMessage({
        id: uuidv4(),
        message: `${selectedFile.name} attached successfully.`,
        role: MessageRole.Attach,
        isChunk: false,
        attached: null,
      });

      console.log('attach message sent');
      posthog.capture(PosthogEventsNames.qcounsel_file_attached, {
        distinct_id: user_id, // Use a unique identifier for the user/session
        session_id: sessionId,
        source: 'outlook',
        time: new Date().toISOString(),
        name: selectedFile.name,
        tab_name: 'qcounsel',
      });
    } catch (error) {
      console.error('Error processing file:', error);
      setfailure(true);
      setTimeout(() => {
        setfailure(false);
      }, 2000);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onMessageSend = (value: string) => {
    const messageData = {
      message: value,
      attached: fileContent ? fileContent : null,
    };
    console.log('Sending a chat message:', messageData);
    let id = uuidv4();
    sendChatMessage(
      {
        id: id,
        message: value,
        role: MessageRole.User,
        isChunk: false,
        attached: fileContent ? fileContent : null,
        time: new Date(),
      },
      true,
      sessionId
    );
    localStorage.setItem('isStopStreaming', 'false');
    posthog.capture(PosthogEventsNames.qcounsel_query_submitted, {
      distinct_id: user_id, // Use a unique identifier for the user/session
      session_id: sessionId,
      soruce: 'outlook',
      query_time: new Date().toISOString(),
      query_text: value,
      query_messageId: id,
      tab_name: 'qcounsel',
    });
  };

  useEffect(() => {
    console.log(isStreaming, localStorage.getItem('isStopStreaming'));
  }, [localStorage, isStreaming]);

  return (
    <div id="chatCnt">
      <QCounsel
        isSocketReady={isSocketReady}
        userOfflineProcess={userOfflineProcess}
        chatMessages={chatMessages}
        sendFeedbackGet={sendFeedbackGet}
        onMessageSend={onMessageSend}
        handleFileChange={handleFileChange}
        loading={loading}
        failure={failure}
        setFileContent={setFileContent}
        handleRegenerate={handleRegenerate}
        isStreaming={isStreaming}
        stopResponding={() => {
          localStorage.setItem('isStopStreaming', 'true');
          setIsStreaming(false);
        }}
        positionTop="0px"
        clearMessage={() => {
          clearQcounselMessages();
        }}
        posthog={posthog}
        user_id={user_id}
        sessionId={sessionId}
        setSessionId={setSessionId}
      />
    </div>
  );
};
