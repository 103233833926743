import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

// Initialize the react plugin without browser history
const reactPlugin = new ReactPlugin();

class TelemetryService {
  private static instance: TelemetryService;
  private appInsights: ApplicationInsights;
  private isInitialized: boolean = false;
  private consoleLogger: boolean = false;

  private constructor() {
    // Only log in development when no connection string is available
    if (process.env.NODE_ENV === 'development' && !process.env.APPINSIGHTS_CONNECTION_STRING) {
      this.consoleLogger = true;
      this.isInitialized = true;
      return;
    }

    try {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: process.env.APPINSIGHTS_CONNECTION_STRING || '',
          enableAutoRouteTracking: false,
          enableCorsCorrelation: false,
          enableRequestHeaderTracking: false,
          enableResponseHeaderTracking: false,
          enableAjaxPerfTracking: true,
          maxAjaxCallsPerView: 100,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { 
              enableAutoRouteTracking: false
            }
          },
          disableFetchTracking: true,
          samplingPercentage: 33.33,
          excludeRequestFromAutoTrackingPatterns: [
            /.*applicationinsights.*/, 
            /.*azure\.com.*/,
          ],
          enableDebug: process.env.NODE_ENV !== 'production',
          loggingLevelConsole: 1,
        },
      });
    } catch (error) {
      console.error('Error in TelemetryService constructor:', error);
      this.consoleLogger = true;
      this.isInitialized = true;
    }
  }

  public static getInstance(): TelemetryService {
    if (!TelemetryService.instance) {
      TelemetryService.instance = new TelemetryService();
    }
    return TelemetryService.instance;
  }

  public initialize(): Promise<void> {
    return new Promise((resolve) => {
      // If already initialized or using console logger, resolve immediately
      if (this.isInitialized || this.consoleLogger) {
        resolve();
        return;
      }

      if (!this.appInsights?.config.connectionString) {
        console.warn('Application Insights connection string not provided - falling back to console logging');
        this.consoleLogger = true;
        this.isInitialized = true;
        resolve();
        return;
      }

      try {
        this.appInsights.loadAppInsights();
        
        // Verify initialization
        if (!this.appInsights.context) {
          console.warn('AppInsights context not available after initialization');
        }

        // Set cloud role
        const cloudRole = process.env.ENVIRONMENT === 'prod' ? 'outlook-addin' : `outlook-addin-${process.env.ENVIRONMENT}`;
        this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
          envelope.tags = envelope.tags || {};
          envelope.tags['ai.cloud.role'] = cloudRole;
        });

        // Add client context
        this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
          envelope.tags = envelope.tags || {};
          envelope.tags['ai.cloud.roleInstance'] = window.location.hostname;
        });

        this.setupErrorHandling();
        this.setupOfficeTracking();
        
        this.isInitialized = true;
        resolve();

      } catch (error) {
        console.warn('Failed to initialize Application Insights, falling back to console logging:', error);
        this.consoleLogger = true;
        this.isInitialized = true;
        resolve();
      }
    });
  }

  private setupErrorHandling(): void {
    // Set up global error handling
    window.onerror = (message, url, lineNumber, columnNumber, error) => {
      this.trackException({
        error: error || new Error(message as string),
        severityLevel: 3,
        properties: {
          url,
          lineNumber,
          columnNumber,
        },
      });
      return false;
    };

    // Set up unhandled promise rejection handling
    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
      this.trackException({
        error: event.reason || new Error('Unhandled Promise rejection'),
        severityLevel: 3,
        properties: {
          status: 'unhandled_promise_rejection',
        },
      });
    };
  }

  private setupOfficeTracking(): void {
    // Track Office.js ready state
    Office.onReady(() => {
      // Get mailbox user details if available
      const userProfile = {
        emailAddress: Office.context.mailbox?.userProfile?.emailAddress || 'unknown',
        timeZone: Office.context.mailbox?.userProfile?.timeZone || 'unknown',
        displayName: Office.context.mailbox?.userProfile?.displayName || 'unknown',
      };

      // Get diagnostic information
      const diagnostics = {
        host: Office.context.host,
        platform: Office.context.platform,
        version: Office.context.diagnostics?.version,
        // Additional Office metadata
        theme: Office.context.officeTheme,
        locale: Office.context.displayLanguage,
        touchEnabled: Office.context.touchEnabled || false,
        licenseType: (Office.context.license as { type?: string })?.type || 'unknown',
      };

      this.trackEvent({
        name: 'OfficeInitialized',
        properties: {
          ...diagnostics,
          // Only include non-PII user info in telemetry
          timeZone: userProfile.timeZone,
          isAuthenticated: !!userProfile.emailAddress,
        }
      });

      // Store the full user profile in the telemetry context for session tracking
      if (userProfile.emailAddress && !this.consoleLogger) {
        const userId = this.hashUserIdentifier(userProfile.emailAddress);
        this.setAuthenticatedUserContext(userId);
      }
    });
  }

  // Wrapper methods for common telemetry operations with console fallback
  public trackEvent(event: { name: string; properties?: { [key: string]: any } }): void {
    if (this.consoleLogger) {
      console.log('Telemetry Event (Console Logger):', event);
      return;
    }
    this.appInsights.trackEvent(event);
  }

  public trackException(exception: {
    error: Error;
    severityLevel?: number;
    properties?: { [key: string]: any };
  }): void {
    if (this.consoleLogger) {
      console.error('Telemetry Exception (Console Logger):', exception);
      return;
    }
    this.appInsights.trackException(exception);
  }

  public trackMetric(metric: {
    name: string;
    average: number;
    properties?: { [key: string]: any };
  }): void {
    if (this.consoleLogger) {
      console.log('Telemetry Metric:', metric);
      return;
    }
    this.appInsights.trackMetric(metric);
  }

  public trackTrace(trace: { message: string; properties?: { [key: string]: any } }): void {
    if (this.consoleLogger) {
      console.log('Telemetry Trace:', trace);
      return;
    }
    this.appInsights.trackTrace(trace);
  }

  public startTrackEvent(name: string): void {
    if (!this.consoleLogger) {
      this.appInsights.startTrackEvent(name);
    }
  }

  public stopTrackEvent(name: string, properties?: { [key: string]: any }): void {
    if (this.consoleLogger) {
      console.log('Telemetry Stop Event:', { name, properties });
      return;
    }
    this.appInsights.stopTrackEvent(name, properties);
  }

  public setAuthenticatedUserContext(userId: string, accountId?: string, storeInCookie = false): void {
    if (!this.consoleLogger) {
      this.appInsights.setAuthenticatedUserContext(userId, accountId, storeInCookie);
    }
  }

  public trackOutlookInteraction(interactionType: string, properties?: { [key: string]: any }): void {
    this.trackEvent({
      name: `Outlook_${interactionType}`,
      properties: {
        ...properties,
        host: Office.context.host,
        platform: Office.context.platform,
      },
    });
  }

  public getAppInsights(): ApplicationInsights {
    return this.appInsights;
  }

  // Helper method to hash user identifiers
  private hashUserIdentifier(identifier: string): string {
    // Simple hash function - in production you might want to use a more robust hashing algorithm
    let hash = 0;
    for (let i = 0; i < identifier.length; i++) {
      const char = identifier.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32-bit integer
    }
    return Math.abs(hash).toString(36);
  }
}

export const telemetryService = TelemetryService.getInstance();
export const getAppInsights = () => telemetryService.getAppInsights(); 