import React, { Component, ErrorInfo, ReactNode } from 'react';
import { telemetryService } from '../services/telemetry';
import { MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
    
    telemetryService.trackException({
      error,
      severityLevel: 3,
      properties: {
        component: 'ErrorBoundary',
        componentStack: errorInfo.componentStack,
        errorMessage: error.message,
        errorStack: error.stack,
        errorName: error.name,
        url: window.location.href,
        userAgent: navigator.userAgent
      }
    });
  }

  private handleRetry = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
    // Track retry attempt
    telemetryService.trackEvent({
      name: 'ErrorBoundaryRetry',
      properties: {
        errorMessage: this.state.error?.message,
        timestamp: new Date().toISOString()
      }
    });
  };

  public render() {
    if (this.state.hasError) {
      const isDev = process.env.NODE_ENV === 'development';
      
      return (
        <Stack tokens={{ childrenGap: 10 }}>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
          >
            {isDev ? (
              <>
                <h3>An error occurred in the application</h3>
                <p>{this.state.error?.message}</p>
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {this.state.error?.stack}
                </pre>
                {this.state.errorInfo && (
                  <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {this.state.errorInfo.componentStack}
                  </pre>
                )}
              </>
            ) : (
              <p>
                We encountered an unexpected error. Our team has been notified and is working to resolve the issue.
              </p>
            )}
          </MessageBar>
          <Stack horizontal horizontalAlign="start">
            <PrimaryButton 
              onClick={this.handleRetry}
              text="Try Again"
              title="Attempt to recover from the error"
            />
          </Stack>
        </Stack>
      );
    }

    return this.props.children;
  }
} 