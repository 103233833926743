import React from 'react';
import { createRoot } from 'react-dom/client';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { AppInsightsProvider } from '../contexts/AppInsightsContext';
import { telemetryService } from '../services/telemetry';
import { AppProvider } from '../provider/appProvider';
import { ErrorBoundary } from '../components/ErrorBoundary';
import App from './components/App';
import { PostHogProvider } from 'posthog-js/react';

/* global document, Office, module, require */

declare global {
  interface Window {
    initializeOutlookHandlers?: () => void;
  }
}

let isOfficeInitialized = false;

const title = 'Qanooni Add-in';

const render = (Component) => {
  try {
    console.log('Starting render process...');
    const container = document.getElementById('container');
    if (!container) {
      console.error('Container element not found');
      return;
    }
    console.log('Creating root...');
    const root = createRoot(container);
    root.render(
      <ErrorBoundary>
        <FluentProvider theme={webLightTheme}>
          <AppProvider>
            <AppInsightsProvider>
              <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={{ api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST }}>
                <Component title={title} isOfficeInitialized={isOfficeInitialized} />
              </PostHogProvider>
            </AppInsightsProvider>
          </AppProvider>
        </FluentProvider>
      </ErrorBoundary>
    );
    console.log('Render complete');
    
    // Initialize Outlook handlers after render
    if (window.initializeOutlookHandlers) {
      console.log('Initializing Outlook handlers...');
      window.initializeOutlookHandlers();
    }
  } catch (error) {
    console.error('Error during render:', error);
    telemetryService.trackException({
      error: error instanceof Error ? error : new Error(String(error)),
      severityLevel: 3,
      properties: {
        component: 'Render',
        status: 'render_failed'
      }
    });
  }
};

/* Render application after Office initializes */
Office.onReady((info) => {
  console.log('Office.onReady called with info:', info);
  try {
    isOfficeInitialized = true;
    render(App);
  } catch (error) {
    console.error('Error during Office initialization:', error);
    telemetryService.trackException({
      error: error instanceof Error ? error : new Error(String(error)),
      severityLevel: 3,
      properties: {
        component: 'Root',
        status: 'office_initialization_failed',
        info: JSON.stringify(info)
      }
    });
  }
});
