import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import App from './components/App';
import { AppProvider } from '../provider/appProvider';
const rootElement: HTMLElement = document.getElementById('rootContainer')!;

Office.onReady(async () => {
  const root = createRoot(rootElement);
  const customTheme = {
    ...webLightTheme,
    fontFamilyBase: "'Arial', Helvetica, sans-serif", // Custom font
  };
  root.render(
    <FluentProvider theme={customTheme}>
      <AppProvider>
        <App />
      </AppProvider>
    </FluentProvider>
  );
});
