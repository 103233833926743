import React, { useEffect } from 'react';
import {
  Button,
  makeStyles,
  shorthands,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
} from '@fluentui/react-components';
import {
  Info16Regular,
  Link16Regular,
  LinkDismiss16Regular,
  MoreHorizontal16Filled,
} from '@fluentui/react-icons';
import { ActionEnum } from '../../../../model/base';
import { useMailboxContent } from '../../../../provider/MailboxContentProvider';

interface EmailTabProps {
  showBtn: React.Dispatch<React.SetStateAction<boolean>>;
  setEnablePop: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmailTab: React.FC<EmailTabProps> = ({
  showBtn,
  setEnablePop,
}) => {
  const emailTabstyles = useEmailStyles();
  const {
    LinkedEmailList,
    linkedEmailList,
    setLinkedEmailList,
    LinkedEmailDetail,
    isSocketReady,
    GetFeatureFlag,
    featureFlag,
  } = useMailboxContent();
  const email_id = Office.context.mailbox.item.itemId;

  const [featureMHTester, setFeatureMHTester] = React.useState<string>('');

  const currentEmail = linkedEmailList.filter(l => l.is_current === true)[0];

  useEffect(() => {
    if (isSocketReady) {
      GetFeatureFlag({
        action: ActionEnum.GetFeatureFlagRequest,
        feature_name: 'matter_history_tester',
      });
    }
  }, [isSocketReady]);

  useEffect(() => {
    if (featureFlag !== undefined) {
      console.log('Feature Flag Response:', featureFlag);
      if (featureFlag.feature_name == 'matter_history_tester') {
        setFeatureMHTester(featureFlag.feature_value);
      }
    }
  }, [featureFlag]);

  useEffect(() => {
    if (featureMHTester) {
      console.log('featureMHTester:', featureMHTester);
    }
  }, [featureMHTester]);

  useEffect(() => {
    LinkedEmailList({
      action: ActionEnum.LinkedEmailListRequest,
      email_id: email_id,
    });
  }, []);
  return (
    <div className={emailTabstyles.emailTabContainer}>
      <Table className={emailTabstyles.table}>
        <TableBody>
          {linkedEmailList.length > 0 ? (
            linkedEmailList.map((item, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    className={emailTabstyles.expandedSection}
                  >
                    <div className={emailTabstyles.expandedContent}>
                      <div>
                        <div
                        //  className={emailTabstyles.expandedContentHeaderTop}
                        >
                          <div style={{ width: '80%', wordBreak: 'break-all' }}>
                            <span> {item.sender.split(' <')[0]}</span>
                            <span className={emailTabstyles.senderEmail}>
                              ({item.sender.match(/<(.+)>/)[1]})
                            </span>
                          </div>
                          <div
                            className={
                              emailTabstyles.expandedContentHeaderTopDown
                            }
                          >
                            <h5 className={emailTabstyles.subjectHeader}>
                              {item.subject}
                            </h5>
                            {/* <ErrorCircle16Regular /> */}
                          </div>
                        </div>
                        <div
                          className={emailTabstyles.expandedContentHeaderDown}
                        >
                          {new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          }).format(new Date(item.received_at))}
                        </div>
                      </div>
                      <div className={emailTabstyles.actionIcons}>
                        <Tooltip content={'info'} relationship="label">
                          <Button
                            appearance="transparent"
                            icon={<Info16Regular />}
                            onClick={() => {
                              LinkedEmailDetail({
                                action: ActionEnum.LinkedEmailDetailRequest,
                                email_id: item.id,
                              });
                              setEnablePop(true);
                            }}
                            className={emailTabstyles.actionIcon}
                          />
                        </Tooltip>
                        {currentEmail &&
                        item.conversation_id !==
                          linkedEmailList.filter(l => l.is_current === true)[0]
                            .conversation_id ? (
                          <Tooltip
                            content={item.linked ? 'Unlink' : 'Link Email'}
                            relationship="label"
                          >
                            <div>
                              <Button
                                appearance="transparent"
                                icon={
                                  item.linked ? (
                                    <LinkDismiss16Regular />
                                  ) : (
                                    <Link16Regular />
                                  )
                                }
                                className={emailTabstyles.actionIcon}
                                onClick={() => {
                                  setLinkedEmailList(prev => {
                                    let arr = [...prev];
                                    item = arr[index];
                                    item.linked = !prev[index].linked;
                                    item.updated = true;
                                    return arr;
                                  });
                                  showBtn(true);
                                }}
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          currentEmail && (
                            <>
                              {featureMHTester === 'true' ? (
                                <Tooltip content={'more'} relationship="label">
                                  <div>
                                    <Button
                                      appearance="transparent"
                                      icon={<MoreHorizontal16Filled />}
                                      onClick={() => {
                                        LinkedEmailDetail({
                                          action:
                                            ActionEnum.LinkedEmailDetailRequest,
                                          email_id:
                                            item.id +
                                            '&' +
                                            String(item.is_current),
                                        });
                                        setEnablePop(true);
                                      }}
                                      className={emailTabstyles.actionIcon}
                                    />
                                  </div>
                                </Tooltip>
                              ) : (
                                <Button
                                  appearance="transparent"
                                  icon={
                                    <Link16Regular
                                      style={{ display: 'none' }}
                                    />
                                  }
                                  className={emailTabstyles.actionIcon}
                                  style={{ cursor: 'default' }}
                                />
                              )}
                            </>
                          )
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const useEmailStyles = makeStyles({
  emailTabContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  emailTabContainerHeader: {
    height: '7%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    ...shorthands.padding('10px', '7px', '10px', '0px'),
    ...shorthands.borderBottom('1px', 'solid', '#c3bfbf'),
  },
  emailTabContentSelect: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tab: {
    height: '12px',
    lineHeight: '11px',
    ...shorthands.flex(1),
    textAlign: 'center',
    ...shorthands.padding('10px'),
    cursor: 'pointer',
    backgroundColor: '#f0f0f0',
    ...shorthands.borderRadius('20px'),
    ...shorthands.transition('background-color', '1s', 'ease'),
  },
  activeTab: {
    height: '12px',
    lineHeight: '11px',
    ...shorthands.flex(1),
    textAlign: 'center',
    ...shorthands.padding('10px'),
    cursor: 'pointer',
    backgroundColor: '#bcbcc2',
    ...shorthands.borderRadius('20px'),
    color: 'blue',
    ...shorthands.transition('background-color', '1s', 'ease'),
  },
  informationalText: {
    ...shorthands.padding('15px', '20px'),
    backgroundColor: '#fff',
    ...shorthands.borderRadius('8px'),
    marginBottom: '10px',
    color: '#555',
  },
  table: {
    backgroundColor: '#fff',
    ...shorthands.borderRadius('8px'),
    ...shorthands.padding('10px', '20px'),
    tableLayout: 'fixed',
    width: '100%' /* Ensure the table takes full available width */,
  },
  headerCell: {
    fontWeight: 'bold',
    textAlign: 'left',
    ...shorthands.padding('5px', '5px'),
    fontSize: '12px',
  },
  headerFirstCell: {
    width: '25%',
    paddingLeft: '5px',
    '& div': {
      paddingLeft: '10px',
    },
  },
  headerFourthCell: {
    paddingLeft: '14px',
  },
  headerSecondCell: {
    width: '25%',
  },
  headerThirdCell: {
    width: '19%' /* Adjust width for "Date" */,
  },
  cell: {
    ...shorthands.padding('10px', '10px'),
    textAlign: 'left',
    fontSize: '12px',
    whiteSpace: 'nowrap', // Prevent text wrapping
    ...shorthands.overflow('hidden'), // Hide overflow content
    textOverflow: 'ellipsis', // Show ellipsis for long content
  },
  fromCell: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '150px', // Adjust this width as per design
    whiteSpace: 'nowrap', // Prevent line breaks
    ...shorthands.overflow('hidden'), // Hide text that overflows
    textOverflow: 'ellipsis', // Show ellipsis for long text
  },
  formCellSpan: {
    whiteSpace: 'nowrap',
    ...shorthands.overflow('hidden'),
    textOverflow: 'ellipsis',
  },
  documentCell: {
    whiteSpace: 'nowrap',
    ...shorthands.overflow('hidden'),
    textOverflow: 'ellipsis',
    maxWidth: '35vw', // Adjust to fit your design
  },
  documentCellSpan: {
    whiteSpace: 'nowrap',
    ...shorthands.overflow('hidden'),
    textOverflow: 'ellipsis',
  },
  fileIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
    marginTop: '15px',
  },
  moreItems: {
    color: '#3ba2f7',
    fontSize: '12px',
    cursor: 'pointer',
  },
  actionIcons: {
    display: 'flex',
    position: 'absolute',
    top: '12px',
    right: '12px',
  },
  actionIcon: {
    fontSize: '10px',
    ...shorthands.padding('0px'),
    minWidth: '16px',
  },
  button: {
    cursor: 'pointer',
  },
  expandedSection: {
    width: '100%',
    backgroundColor: '#f9f9f9',
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('8px'),
    marginTop: '15px',
  },
  expandedContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  expandedContentHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  expandedContentHeaderTop: {
    width: '100%',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  expandedContentHeaderTopDown: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subjectHeader: {
    marginTop: '4px',
    marginBottom: '8px',
    marginLeft: '0',
    marginRight: '0',
  },
  expandedContentHeaderDown: {
    width: '100%',
    alignItems: 'center',
    fontSize: '10px',
  },
  documentShowPart: {
    display: 'flex',
    flexDirection: 'row',
  },
  documents: {
    display: 'flex',
    flexDirection: 'column',
  },
  documentButton: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    ...shorthands.padding('8px'),
    backgroundColor: '#f5f5f5',
    ...shorthands.borderRadius('15px'),
    cursor: 'pointer',
  },
  senderEmail: {
    fontSize: '12px',
    color: '#888',
    marginLeft: '5px',
  },
  docBtn: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.padding('8px', '12px'),
    ...shorthands.borderRadius('20px'),
    backgroundColor: '#e8e8e8',
    ...shorthands.transition('box-shadow', '2s', 'ease'),
    ':hover': {
      backgroundColor: '#d1d1d1',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.15)',
    },
    ...shorthands.transition('background-color', '0.3s', 'ease'),
    ':hover > hoverActions': {
      display: 'flex',
    },
  },
  filesIcon: {
    marginRight: '8px',
  },
  hoverActions: {
    display: 'none',
    position: 'absolute',
    right: '8px',
    ...shorthands.gap('5px'),
    top: '10%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    ':hover': {
      display: 'flex',
    },
  },
  documentContent: {
    maxHeight: '75px',
    overflowY: 'scroll',
    backgroundColor: '#f5f5f5',
    ...shorthands.borderRadius('8px'),
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #e8e8e8',
    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#e8e8e8',
      ...shorthands.borderRadius('10px'),
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      ...shorthands.borderRadius('10px'),
      ...shorthands.border('3px', 'solid', '#e8e8e8'),
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
});
