import React, { createContext, useContext, ReactNode, useState, useEffect, useCallback, useRef } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { telemetryService } from '../services/telemetry';

interface InitializationStatus {
  initialized: boolean;
  attempts: number;
  lastAttempt: Date | null;
  lastError: string | null;
}

interface AppInsightsContextType {
  appInsights: ApplicationInsights | null;
  isLoading: boolean;
  error: Error | null;
  initStatus: InitializationStatus;
  // Fallback tracking methods that work even if AppInsights fails
  trackEvent: (name: string, properties?: { [key: string]: any }) => void;
  trackException: (error: Error, properties?: { [key: string]: any }) => void;
  retryInitialization: () => Promise<void>;
}

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY_MS = 2000;

const AppInsightsContext = createContext<AppInsightsContextType | undefined>(undefined);

interface AppInsightsProviderProps {
  children: ReactNode;
}

export const AppInsightsProvider: React.FC<AppInsightsProviderProps> = ({ children }) => {
  console.log('AppInsightsProvider mounting');

  const [appInsights, setAppInsights] = useState<ApplicationInsights | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [initStatus, setInitStatus] = useState<InitializationStatus>({
    initialized: false,
    attempts: 0,
    lastAttempt: null,
    lastError: null
  });
  
  // Add initialization lock
  const initializationInProgress = useRef(false);

  const initializeAppInsights = useCallback(async () => {
    // Prevent concurrent initialization attempts
    if (initializationInProgress.current || initStatus.initialized) {
      console.log('Initialization already in progress or completed, skipping', {
        inProgress: initializationInProgress.current,
        isInitialized: initStatus.initialized
      });
      return;
    }

    initializationInProgress.current = true;
    const startTime = Date.now();

    try {
      console.log('Starting AppInsights initialization process...', {
        environment: process.env.NODE_ENV,
        hasConnectionString: !!process.env.APPINSIGHTS_CONNECTION_STRING,
        attempts: initStatus.attempts,
        timeSinceMount: Date.now() - startTime
      });

      setIsLoading(true);
      const newAttempts = initStatus.attempts + 1;
      
      if (newAttempts > MAX_RETRY_ATTEMPTS) {
        const error = new Error(`Failed to initialize after ${MAX_RETRY_ATTEMPTS} attempts`);
        console.error('Max retry attempts exceeded:', error);
        setError(error);
        return;
      }

      setInitStatus(prev => ({
        ...prev,
        attempts: newAttempts,
        lastAttempt: new Date()
      }));

      const connectionString = process.env.APPINSIGHTS_CONNECTION_STRING;
      if (!connectionString) {
        throw new Error('AppInsights connection string is not configured');
      }

      const appInsightsInstance = telemetryService.getAppInsights();
      await telemetryService.initialize();
      
      console.log('AppInsights initialization successful', {
        timeSinceMount: Date.now() - startTime,
        hasInstance: !!appInsightsInstance
      });
      
      setAppInsights(appInsightsInstance);
      setError(null);
      setInitStatus(prev => ({
        ...prev,
        initialized: true,
        lastError: null
      }));

    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown initialization error';
      console.error('Failed to initialize AppInsights:', {
        error: err,
        attempts: initStatus.attempts,
        timestamp: new Date().toISOString()
      });

      setError(err instanceof Error ? err : new Error(errorMessage));
      setInitStatus(prev => ({
        ...prev,
        lastError: errorMessage
      }));

      if (initStatus.attempts < MAX_RETRY_ATTEMPTS) {
        console.log(`Scheduling retry in ${RETRY_DELAY_MS}ms...`);
        setTimeout(() => {
          initializationInProgress.current = false;
          initializeAppInsights();
        }, RETRY_DELAY_MS);
      }
    } finally {
      setIsLoading(false);
      if (initStatus.attempts >= MAX_RETRY_ATTEMPTS) {
        initializationInProgress.current = false;
      }
    }
  }, [initStatus.attempts]);

  // Single initialization effect
  useEffect(() => {
    if (!initStatus.initialized && !isLoading && !initializationInProgress.current) {
      console.log('Triggering initial AppInsights initialization');
      initializeAppInsights();
    }
  }, [initStatus.initialized, isLoading, initializeAppInsights]);

  // Add immediate initialization attempt
  useEffect(() => {
    console.log('Immediate initialization attempt');
    const immediateInit = async () => {
      try {
        await initializeAppInsights();
      } catch (err) {
        console.error('Error in immediate initialization:', err);
      }
    };
    immediateInit();
  }, []); // Empty dependency array for one-time initialization

  // Add initialization status monitoring
  useEffect(() => {
    console.log('Initialization status changed:', {
      initialized: initStatus.initialized,
      attempts: initStatus.attempts,
      lastError: initStatus.lastError,
      hasAppInsights: !!appInsights
    });
  }, [initStatus, appInsights]);

  const retryInitialization = async () => {
    if (!isLoading) {
      setInitStatus(prev => ({
        ...prev,
        attempts: 0,
        lastError: null
      }));
      await initializeAppInsights();
    }
  };

  // Fallback tracking methods that work even if AppInsights fails
  const trackEvent = useCallback((name: string, properties?: { [key: string]: any }) => {
    try {
      telemetryService.trackEvent({ name, properties });
    } catch (error) {
      console.error('AppInsights trackEvent failed:', error);
    }
  }, []);

  const trackException = useCallback((error: Error, properties?: { [key: string]: any }) => {
    try {
      telemetryService.trackException({ error, properties });
    } catch (err) {
      console.error('AppInsights trackException failed:', err);
    }
  }, []);

  const contextValue: AppInsightsContextType = {
    appInsights,
    isLoading,
    error,
    initStatus,
    trackEvent,
    trackException,
    retryInitialization
  };

  return (
    <AppInsightsContext.Provider value={contextValue}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export const useAppInsightsContext = () => {
  const context = useContext(AppInsightsContext);
  if (context === undefined) {
    throw new Error('useAppInsightsContext must be used within an AppInsightsProvider');
  }
  return context;
}; 